<template>
  <div>
    <van-nav-bar left-arrow
                 :title="$t('tbm.查看')"
                 fixed
                 @click-left="$router.go(-1)">
    </van-nav-bar>
    <div style="padding-top:46px;"
         class="applyHolidayView">
      <div>
        <div>
          <p>
            <i class="iconfont icon-jiabanshenqing"></i>
          </p>
          <div>
            <p>{{ $t('tbm.加班申请') }}</p>
            <p>{{ $t('tbm.申请时间') }}：{{form.createTime}}</p>
          </div>
        </div>
        <p :style="{color:form.formColor,'font-size':'16px'}">{{form.formStatusName}}</p>
      </div>
      <div>
        <div v-for="(item,index) in formList"
             :key="index">
          <label>{{item.label}}</label>
          <p>{{item.text && form[item.text]}}</p>
        </div>
      </div>

      <div class="overtimeDetailList">
        <div v-for="(item,index) in personForm.overtimeDetailList"
             :key="index"
             v-show="personForm.overtimeDetailList.length > 0">
          <div v-for="(items,key) in dataList"
               :key="key">
            <label>{{items.label}}</label>
            <p v-if="items.text == 'dateType'">{{typeconversion(item['dateType'])}}</p>
            <p v-else-if="items.text == 'dateDetail'">{{item[items.text].substring(0, 10)}}</p>
            <p v-else>{{item[items.text]}}</p>
          </div>
        </div>
        <div style="background:#eee;display:flex;"
             v-show="personForm.overtimeDetailList.length == 0">
          <img src="@/views/business/images/empty.png"
               alt=""
               style="width:200px;height:150px;margin:20px auto;">
        </div>
      </div>

      <filesList :showFile="true"
                 v-show="filesList.length>0"
                 :filesList="filesList"></filesList>
      <history-panel :pid="form.id"
                     :woType="procConf.woCode"
                     :formNo="form.formNo"></history-panel>

      <div v-if="approvalShow">
        <approval v-if="form"
                  class="approvalFixed"
                  :woCodeValue="procConf.woCode"
                  saveUrl="applyOverTime"
                  :businessInfo="form"
                  :detailPageName="$route.name"
                  :customSubmitBtnName="customSubmitBtnName"
                  :processName="procConf.processInstanceName"
                  :taskId="procConf.taskInstanceId">
        </approval>
      </div>
    </div>
  </div>
</template>
<script>
import filesList from '@/views/business/components/filesList';
import historyPanel from '@/views/business/components/historyPanel';
import approval from '@/components/approval/approval';
export default {
  components: {
    filesList,
    historyPanel,
    approval
  },
  data () {
    return {
      form: {
        id: '',
        createTime: '',
        formStatusName: '',
        formColor: '',
        userName: '',
        deptName: '',
        name: '',
        reason: '',
        formNo: ''
      },
      formStatus: [
        { label: '暂存', color: '#318B80' },
        { label: '审核中', color: '#FF7405' },
        { label: '作废', color: '#999999' },
        { label: '通过审核', color: '#4378BE' },
      ],
      formList: [
        {
          label: '申请人',
          text: 'userName'
        },
        {
          label: '所在部门',
          text: 'deptName'
        },
        {
          label: '加班名称',
          text: 'name'
        },
        {
          label: '加班内容',
          text: 'reason'
        },
        {
          label: '加班明细',
          text: ''
        },
      ],
      dataList: [
        {
          label: '加班日期',
          text: 'dateDetail'
        },
        {
          label: '加班类型',
          text: 'dateType'
        },
        {
          label: '开始时间',
          text: 'beginTime'
        },
        {
          label: '结束时间',
          text: 'endTime'
        },
        {
          label: '加班时长',
          text: 'totalHours'
        },
        {
          label: '折合天数',
          text: 'totalDays'
        },
      ],
      filesList: [],
      personForm: {
        overtimeDetailList: [],
        pkOrg: '',
        pkUser: '',
        pkDept: ''
      },
      procConf: {
        businessKey: '',
        processInstanceName: '加班申请',
        woCode: this.TbmCons.BizCode.TbmApplyOvertime,
        taskInstanceId: '',
        variables: {},
        taskType: '',
        mappingId: '',
        status: '',
        activityId: '',
      },
      approvalShow: false,
      customSubmitBtnName: ['同意', '不同意'],
    }
  },
  methods: {
    typeconversion: (value) => {
      return value === '0' ? '法定节假日' : value === '1' ? '工作日' : '休息日';
    },
    backFillEditData () {
      this.$myHttp({
        method: 'POST',
        url: '/hrtbm/applyOverTime/view',
        data: {
          'entity': {
            'id': this.form.id
          }
        }
      }).then(async res => {
        let backData = res.data.entity;
        if (backData) {
          this.form = backData;
          if (backData.overtimePersonList.length > 0) {
            let detailList = [];
            for (var i = 0; i < backData.overtimePersonList.length; i++) {
              if (backData.overtimePersonList[i].overtimeDetailList.length > 0) {
                detailList = (backData.overtimePersonList[i].overtimeDetailList);
              }
            }
            this.personForm.overtimeDetailList = detailList;
          }
          if (backData.createTime) {
            this.form.createTime = backData.createTime.split(' ')[0];
          }
          this.form.formStatusName = this.formStatus[backData.formStatus].label;
          this.form.formColor = this.formStatus[backData.formStatus].color;

          let deptName = JSON.parse(localStorage.getItem('userInfo')).deptName;
          let userName = JSON.parse(localStorage.getItem('userInfo')).cname;
          let officeoutReason = this.form.reason;
          if (officeoutReason.length > 5) {
            officeoutReason = officeoutReason.substring(0., 5) + '……'
          }
          this.procConf.processInstanceName = this.procConf.processInstanceName + '-' + deptName + ' ' + userName + '-' + officeoutReason + '-' + this.form.totalDays + '天'
          if (this.$route.query.type) {
            this.approvalShow = true;
          }
        }
      });
    },
    getFilesList () {
      this.$myHttp({
        method: 'post',
        url: '/microarch/sys/sysAttchement/list',
        data: {
          'entity': {
            'bizId': this.form.id
          }
        }
      }).then(res => {
        let backData = res.data;
        if (backData) {
          this.filesList = backData
        }
      });
    },
  },
  created () {
    this.form.id = this.$route.query.businessKey;
    if (this.$route.query['row']) {
      let row = JSON.parse(this.$route.query['row']);
      if (row && row['pid']) {
        this.form.id = this.EhrUtils.isEmpty(this.form.id) ? row['businessKey'] : this.form.id;
        this.procConf.taskInstanceId = row['id'];
        this.procConf.taskType = row['taskType'];
        this.procConf.status = row['status'];
        this.procConf.activityId = row['activityId'];
        this.procConf.activityCode = row['activityCode'];// 节点编码
      }
    }
    this.backFillEditData();
    this.getFilesList();
  }
}
</script>
<style scoped>
.overtimeDetailList {
  padding: 10px 10px 0 10px;
}
.overtimeDetailList > div {
  background: #fff;
  border-radius: 15px;
  padding: 10px;
  margin-bottom: 10px;
}
.overtimeDetailList > div > div {
  display: flex;
  margin: 10px 0;
}
.overtimeDetailList > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 70px;
}
</style>

<style>
.applyHolidayView > div:nth-child(1) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px 20px 10px 10px;
  background: #fff;
  margin-bottom: 10px;
}
.applyHolidayView > div:nth-child(1) > div {
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(1) > div > p {
  width: 48px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(147, 162, 253, 1);
  margin-right: 10px;
}
.applyHolidayView > div:nth-child(1) > div > p i {
  width: 27px;
  height: 27px;
  font-size: 27px;
  color: #fff;
}
.applyHolidayView > div:nth-child(1) > div > div {
  font-size: 18px;
  color: rgb(51, 51, 51);
}
.applyHolidayView > div:nth-child(1) > div > div p:nth-child(2) {
  font-size: 14px;
  color: rgb(102, 102, 102);
  margin-top: 5px;
}
.applyHolidayView > div:nth-child(2) {
  background: #fff;
  padding: 0 10px 10px;
}
.applyHolidayView > div:nth-child(2) > div {
  height: 44px;
  display: flex;
  align-items: center;
}
.applyHolidayView > div:nth-child(2) > div label {
  margin-right: 20px;
  text-align: right;
  color: rgb(102, 102, 102);
  font-size: 16px;
  width: 70px;
}
</style>
